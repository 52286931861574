import React, { useState } from "react";

const Whatsapp = () => {
  const [isHovered, setHovered] = useState(false);
  const whatsappNumber = "+919353800375"; // WhatsApp contact number

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div>
      <div
        className={`rounded ${
          isHovered ? "rounded-5" : "rounded-circle"
        } shadow d-flex text-center justify-content-${
          isHovered ? "between" : "center"
        } align-items-center p-${isHovered ? "2" : "0"}`}
        style={{
          width: isHovered ? "180px" : "50px",
          cursor: "pointer",
          height: "50px",
          background: "white",
          position: "fixed",
          top: "40%",
          right: "0px",
          zIndex: 999,
          transition:
            "width 0.3s ease, height 0.3s ease, border-radius 0.3s ease",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() =>
          window.open(
            `https://wa.me/${whatsappNumber.replace(/\+/g, "")}`,
            "_blank"
          )
        }
      >
        {isHovered ? (
          <>
            <div>
              <img
                src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-whatsapp-mobile-software-icon-png-image_6315991.png"
                style={{ width: "30px", height: "30px" }}
                className="img-fluid"
                alt="WhatsApp"
                title="WhatsApp"

              />
            </div>
            <div>
              Whatsapp us
              <br />
              {whatsappNumber}
            </div>
          </>
        ) : (
          <>
            <div>
              <img
                src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-whatsapp-mobile-software-icon-png-image_6315991.png"
                style={{ width: "30px", height: "30px" }}
                className="img-fluid"
                alt="WhatsApp"
                title="WhatsApp"

              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Whatsapp;
